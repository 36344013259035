import * as React from 'react'
import { Page } from '../components/page'
import { Seo } from '../components/seo'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import { Teaser } from '../components/teaser'
import { Content } from '../components/content'
import { IconBoxWrapper, IconBox } from '../components/iconbox'
import { HoverBox } from '../components/hoverbox'

const IndexPage = () => {
    const { t }: { t: any } = useTranslation()

    return (
        <Page pageName={'INDEX'}>
            <Seo title={t('PAGES.INDEX.TITLE')} />
            <Teaser pageName={'INDEX'} hideText fullscreen />
            <Content flex showOverflow style={{ padding: 0 }}>
                <HoverBox />
            </Content>
        </Page>
    )
}

export default IndexPage

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
