import * as React from 'react'
import classNames from 'classnames'
import * as teaserStyles from './teaser.module.css'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

export interface TeaserProps extends React.PropsWithChildren {
    pageName?: string
    header?: string
    text?: string
    variant?: string
    image?: string
    fullscreen?: boolean
    className?: string
    hideText?: boolean
}

export const Teaser: React.FC<TeaserProps> = ({
    pageName,
    children,
    header,
    text,
    variant,
    image,
    fullscreen,
    hideText,
    className,
}) => {
    const { t }: { t: any } = useTranslation()

    return (
        <div
            className={classNames(
                className,
                variant ? teaserStyles[variant] : null,
                teaserStyles.teaser,
                {
                    [teaserStyles.noChildren]: !children,
                    [teaserStyles.image]: !!image || fullscreen,
                }
            )}
            style={{
                backgroundImage: image ? `url(${image})` : undefined,
            }}
        >
            {!hideText && (
                <div className={teaserStyles.wrapper}>
                    <ReactMarkdown className={teaserStyles.teaserHeader}>
                        {header || t('TEASER.' + pageName + '_HEADER')}
                    </ReactMarkdown>
                    <ReactMarkdown className={teaserStyles.teaserText}>
                        {text || t('TEASER.' + pageName + '_TEXT')}
                    </ReactMarkdown>
                </div>
            )}
            {children}
        </div>
    )
}
