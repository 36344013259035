import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as hoverBoxStyles from './hoverbox.module.css'
import { useTranslation } from 'react-i18next'

interface HoverBoxProps {}

export const HoverBox: React.FC<HoverBoxProps> = () => {
    const { t }: { t: any } = useTranslation()
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    links {
                        icon
                        name
                        url
                    }
                }
            }
        }
    `)

    return (
        <div className={hoverBoxStyles.wrapper}>
            <img src="/images/logo.png" className={hoverBoxStyles.image} />
            <h2 style={{ display: 'none' }}>{data.site.siteMetadata.title}</h2>
            <div className={hoverBoxStyles.links}>
                {data.site.siteMetadata.links.map((link: any, i: int) => (
                    <a href={link.url} className={hoverBoxStyles.link} key={i}>
                        <img src={`/images/social/${link.icon}.svg`} />
                        <span>{link.name}</span>
                    </a>
                ))}
            </div>
        </div>
    )
}
